function nameToVCard(name) {
  const lines = [];
  const components = [
    name.last,
    name.first,
    name.middle,
    name.prefix,
    name.suffix,
  ];
  if (components.some((x) => x && x.length > 0)) {
    lines.push(`N:${components.map(vCardEncode).join(";")}`);
  }
  return lines;
}

function vCardEncode(value) {
  return value.replace(/,/g, "\\,").replace(/;/g, "\\;");
}

function phoneToVCard(phone) {
  // TEL (V3): https://tools.ietf.org/html/rfc2426#section-3.3.1
  // TEL (V4): https://tools.ietf.org/html/rfc6350#section-6.4.1
  const v4 = false;
  let s = v4 ? "TEL;VALUE=uri" : "TEL";
  const types = [];

  switch (phone.label) {
    case "faxWork":
      types.push("fax", "work");
      break;
    case "mobile":
      types.push("cell", v4 ? "text" : "msg");
      break;
    default:
  }

  if (!v4 && phone.isPrimary) {
    types.push("pref");
  }

  if (types.length === 1) {
    s += `;TYPE=${types[0]}`;
  } else if (types.length > 1) {
    s += v4 ? `;TYPE="${types.join(",")}"` : `;TYPE=${types.join(",")}`;
  }

  if (v4 && phone.isPrimary) {
    s += ";PREF=1";
  }

  s += v4
    ? `:tel:${vCardEncode(phone.number)}`
    : `:${vCardEncode(phone.number)}`;
  return [s];
}

function emailToVCard(email) {
  // EMAIL (V3): https://tools.ietf.org/html/rfc2426#section-3.3.2
  // EMAIL (V4): https://tools.ietf.org/html/rfc6350#section-6.4.2
  let s = "EMAIL";
  const v4 = false;

  if (!v4) {
    s += ";TYPE=internet";
  } else {
    switch (email.label) {
      case "home":
        s += ";TYPE=home";
        break;
      case "work":
        s += ";TYPE=work";
        break;
      default:
    }
  }

  if (email.isPrimary) {
    s += v4 ? ";PREF=1" : ",pref";
  }

  s += `:${vCardEncode(email.address)}`;
  return [s];
}

function addressToVCard(address) {
  // ADR (V3): https://tools.ietf.org/html/rfc2426#section-3.2.1
  // ADR (V4): https://tools.ietf.org/html/rfc6350#section-6.3.1
  let s = "ADR";
  const v4 = false;

  if (!v4) {
    switch (address.label) {
      case "home":
        s += ";TYPE=home";
        break;
      case "work":
        s += ";TYPE=work";
        break;
      default:
    }
  } else {
    switch (address.label) {
      case "home":
        s += ";LABEL=home";
        break;
      case "work":
        s += ";LABEL=work";
        break;
      case "other":
        s += ";LABEL=other";
        break;
      case "custom":
        s += `;LABEL="${vCardEncode(address.customLabel)}"`;
        break;
      default:
    }
  }

  if (
    address.street ||
    address.pobox ||
    address.city ||
    address.state ||
    address.postalCode
  ) {
    s += `:${vCardEncode(address.pobox)};;${vCardEncode(
      address.street
    )};${vCardEncode(address.city)};${vCardEncode(address.state)};${vCardEncode(
      address.postalCode
    )};${vCardEncode(address.country)}`;
  } else {
    s += `:;;${vCardEncode(address.address)};;;;`;
  }

  return [s];
}

function organizationToVCard(organization) {
  const lines = [];
  if (organization.company) {
    lines.push(`ORG:${vCardEncode(organization.company)}`);
  }
  if (organization.title) {
    lines.push(`TITLE:${vCardEncode(organization.title)}`);
  }
  return lines;
}
function websiteToVCard(website) {
  return [`URL:${vCardEncode(website.url)}`];
}
function socialMediaToVCard(socialMedia) {
  const protocol =
    socialMedia.label === "custom"
      ? socialMedia.customLabel
      : socialMedia.label;
  return [`IMPP:${vCardEncode(protocol)}:${vCardEncode(socialMedia.userName)}`];
}

export async function fetchPhotoAsBase64(url) {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result.split(",")[1]);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export function toVCard(
  mySmartcard,
  myphotoByte,
  { withPhoto = false, productId = null, includeDate = false } = {}
) {
  const v4 = false;
  const lines = ["BEGIN:VCARD", "VERSION:3.0"];

  if (productId) {
    lines.push(`PRODID:${productId}`);
  }
  if (includeDate) {
    lines.push(`REV:${new Date().toISOString()}`);
  }
  if (mySmartcard.displayName) {
    lines.push(`FN:${mySmartcard.displayName}`);
  }
  if (withPhoto && myphotoByte) {
    const prefix = v4
      ? "PHOTO:data:image/jpeg;base64,"
      : "PHOTO;ENCODING=b;TYPE=JPEG:";
    lines.push(prefix + myphotoByte);

  }

  lines.push(
    ...nameToVCard(mySmartcard.name),
    ...mySmartcard.phones.map(phoneToVCard).flat(),
    ...mySmartcard.emails.map(emailToVCard).flat(),
    ...mySmartcard.addresses.map(addressToVCard).flat(),
    ...mySmartcard.organizations.map(organizationToVCard).flat(),
    ...mySmartcard.websites.map(websiteToVCard).flat(),
    ...mySmartcard.socialMedias.map(socialMediaToVCard).flat()
  );

  lines.push("END:VCARD");
  return lines.join("\n");
}
