<template>

<div class="container" v-if="mysmartcard && myphotoUrl!==''" >
    <ProfileCard :mysmartcard="mysmartcard" :myphotoUrl="myphotoUrl" />
    <ContactInfo :mysmartcard="mysmartcard" /> 
    <VideoPage :mysmartcard="mysmartcard" />
    <LinksPage :mysmartcard="mysmartcard" />
  </div>
  <div v-else>
    <LoadingPage />
  </div>
</template>

<script>
// @ is an alias to /src
import ProfileCard from "@/components/ProfileCard.vue";
import LoadingPage from "@/components/LoadingPage.vue";
import ContactInfo from "@/components/ContactInfo.vue";
import VideoPage from '@/components/VideosPage.vue';
import LinksPage from "@/components/LinksPage.vue";

export default {
  name: "HomeView",
  components: {
    ProfileCard,
    ContactInfo,
    VideoPage,
    LinksPage,
    LoadingPage,

  },
  props:{
    mysmartcard:{
      type: Object,
      Required:true,
    },
    myphotoUrl:{
      type: String,
      Required:true,
    }
  },
};
</script>

<style src="@/assets/styles/shared-styles.css">



</style>
