<template>
  <!-- Profile Section -->

  <div class="profile-card">
    <div class="profile-header" v-if="myphotoUrl">
      <img v-bind:src="myphotoUrl" alt="Profile Image" class="profile-img" />
    </div>
    <div class="profile-body" v-if="mysmartcard">
      <h2>{{ mysmartcard.name.first }} {{ mysmartcard.name.last }}</h2>
      <p class="profession" v-if="mysmartcard.organizations.length > 0">{{ mysmartcard.organizations[0].title }}</p>
      <p class="profession" v-if="mysmartcard.organizations.length > 0">{{ mysmartcard.organizations[0].company }}</p>
      <div class="social-icons">
        <a v-if="xLink.length > 0" v-bind:href="xLink[0].userName" target="_blank">
          <Icon icon="simple-icons:x" width="16" height="16" />
        </a>
        <a v-if="facebookLink.length > 0" v-bind:href="facebookLink[0].userName" target="_blank">
          <Icon icon="simple-icons:facebook" width="16" height="16" />
        </a>
        <a v-if="customLink.length > 0" v-bind:href="customLink[0].userName" target="_blank">
          <Icon icon="mdi:external-link" />
        </a>
        <a v-if="linkedInLink.length > 0" v-bind:href="linkedInLink[0].userName" target="_blank">
          <Icon icon="simple-icons:linkedin" width="16" height="16" />
        </a>
      </div>
      <div class="profile-footer">
        <button @click="download" class="btn">
          <Icon icon="line-md:downloading-loop" width="24" height="24" style="color: black" />
          <a style="padding-left: 5px">Download My contact</a>
        </button>
        <!-- <button class="btn">
                        <i class="fas fa-envelope"></i> Contact Me
                    </button>-->
      </div>
    </div>
  </div>
</template>

<script>
//import {saveAs} from 'file-saver';
import { fetchPhotoAsBase64, toVCard } from "@/composables/getvCard";
import { Icon } from "@iconify/vue";

import { ref, onMounted, computed, watchEffect } from "vue";
export default {
  name: "ProfileCard",
  props: ["mysmartcard", "myphotoUrl"],
  components: {
    Icon,
  },
  setup(props) {
    const myphotoByte=ref(null);

    
    watchEffect (async ()=>{
    myphotoByte.value =  await fetchPhotoAsBase64(props.myphotoUrl); 
   });
   const download = async () => {
  const vCardData = await toVCard(props.mysmartcard, myphotoByte.value, { withPhoto: true });
  const blob = new Blob([vCardData], { type: "text/vcard" });
  const url = URL.createObjectURL(blob);
  const filename = `${props.mysmartcard.name.first} ${props.mysmartcard.name.last}'s card.vcf`;

  // Create a link element
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.style.display = 'none';

  // Append the link to the body
  document.body.appendChild(a);

  // Programmatically click the link to trigger the download
  a.click();

  // Remove the link from the document
  document.body.removeChild(a);

  // Revoke the object URL to free up memory
  URL.revokeObjectURL(url);
};


    if (props.mysmartcard.socialMedias) {
      const facebookLink = computed(() => {
        return props.mysmartcard.socialMedias.filter(
          (content) => content.label === "facebook"
        );
      });
      const xLink = computed(() => {
        return props.mysmartcard.socialMedias.filter(
          (content) => content.label === "x"
        );
      });
      const linkedInLink = computed(() => {
        return props.mysmartcard.socialMedias.filter(
          (content) => content.label === "linkedIn"
        );
      });
      const customLink = computed(() => {
        return props.mysmartcard.socialMedias.filter(
          (content) => content.label === "custom"
        );
      });
      console.log("facebook link", facebookLink);
      return { facebookLink, xLink, linkedInLink, customLink, download };
    } else {
      const facebookLink = ref(null);
      const xLink = ref(null);
      const linkedInLink = ref(null);
      const customLink = ref(null);

      console.log("facebook link", facebookLink);
      return { facebookLink, xLink, linkedInLink, customLink, download };
    }

  },

};
</script>

<style src="@/assets/styles/shared-styles.css"></style>
