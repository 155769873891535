// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getAuth} from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAMSxJQpBgJ01Mb_-IbYkjfB6BTKZcbprQ",
  authDomain: "smart-card-c4f01.firebaseapp.com",
  databaseURL: "https://smart-card-c4f01.firebaseio.com",
  projectId: "smart-card-c4f01",
  storageBucket: "smart-card-c4f01.appspot.com",
  messagingSenderId: "813539534721",
  appId: "1:813539534721:web:8e1e960fa66a84ea1caa80",
  measurementId: "G-FHRGTWW1CY"
};

// Initialize Firebase
const smartfireapp = initializeApp(firebaseConfig);
const smartanalytics = getAnalytics();
const smartdb = getFirestore();
const smartauth= getAuth()

export{smartfireapp,smartauth,smartdb, smartanalytics}
