<template>
  <div class="container">
    <!-- <nav class="sidebar" v-if="id">
      <router-link :to="{ name: 'home', params: { Ad: id } }">
        <Icon icon="material-symbols:person" width="24" height="24" /> Contact
      </router-link>
      <router-link :to="{ name: 'about', params: { Ad: id } }"
        > <Icon icon="material-symbols:article" width="24" height="24" />Articles</router-link
      >
      <router-link :to="{ name: 'video', params: { Ad: id } }"
        ><Icon icon="simple-icons:youtube" width="24" height="24" />Videos</router-link
      >
    </nav>-->

    <router-view :mysmartcard="mysmartcard" :myphotoUrl="myphotoUrl"  />
  </div>
</template>

<script>
import { useRouter } from "vue-router";
import { ref, watchEffect } from "vue";
import { smartdb, smartauth } from "@/firebase/config";
import { collection, where, query, getDocs } from "firebase/firestore";
import { signInAnonymously } from "firebase/auth";
import { Icon } from "@iconify/vue";
import defaultImage from '@/assets/images/abc.png';
import {
  getStorage,
  ref as storageRef,
  getDownloadURL,
} from "firebase/storage";


export default {
  components: {
    // Icon,
  },
  setup() {
    const router = useRouter();
    const id = ref(null);
    const mysmartcard = ref(null); // Initialize with an empty object
    const error = ref(null);
    const myphotoUrl = ref("");


    const getmyrouter = async () => {
      await signInAnonymously(smartauth);
      await router.isReady();
    };
    // ****************************   Login function ******************************************************
    const getmycard = async (id) => {
      error.value = null;

      try {
        const todos = collection(smartdb, "contactqrs");
  //      const todos = collection(smartdb, "testqrdocuments");
        
        const q = query(todos, where("_AppShare.cardIdShare", "==", id));
        //console.log(q)
        const card = await getDocs(q);
        //console.log(querySnapshot)
        if (card.empty) {
          throw new Error("No SmartCard found");
        }
        mysmartcard.value = card.docs[0].data();
       /* if (mysmartcard.value) {
          console.log("my CARD OBJECT VALUE", mysmartcard.value);
        }
        console.log("card has photo", mysmartcard.value.HasPhoto);*/
        if (mysmartcard.value.HasPhoto) {
          console.log("card photo url", mysmartcard.value.photoUrl);
          const storage = getStorage();
          const imageRef = storageRef(
            storage,
            `userImages/${mysmartcard.value.photoUrl}`
          );
          console.log("imag ref", imageRef);
          try {
            myphotoUrl.value = await getDownloadURL(imageRef);
            //console.log("photoUrl.value", myphotoUrl.value);
          } catch (error) {
            console.error("Error fetching image URL:", error);
          }
        } else {
          myphotoUrl.value = defaultImage;
        }


if (mysmartcard.value.SmartContent===null){
  mysmartcard.value.SmartContent=[];
}
//console.log("Samrtcontent is here",  mysmartcard.value.SmartContent);


        return { mysmartcard, myphotoUrl };
      } catch (error) {
        console.error("Error fetching data:", error);
        router.push({ name: "NotFound" });
      }
    };

    getmyrouter();
    watchEffect(() => {
      
      id.value = router.currentRoute.value.params.Ad;
      if (id.value) {
        getmycard(id.value);
      }
    });

    return { id, mysmartcard, myphotoUrl };
  },
};
</script>

<style src="@/assets/styles/shared-styles.css"></style>
