import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import NotFound from '@/views/NotFound.vue'

const routes = [
  {
    path: '/:Ad',
    name: 'home',
    component: HomeView,
    props: (route) => ({ mysmartcard: route.params.mysmartcard, myphotoUrl: route.params.myphotoUrl }),
  },
  {
    path: '/about/:Ad',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/AboutView.vue'),
    props: (route) => ({ mysmartcard: route.params.mysmartcard, myphotoUrl: route.params.myphotoUrl }),
  },
  {
    path: '/video/:Ad',
    name: 'video',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/views/VideoView.vue'),
    props: (route) => ({ mysmartcard: route.params.mysmartcard, myphotoUrl: route.params.myphotoUrl }),
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
