// src/utils/fetchMetadata.js
import axios from 'axios';

export async function fetchMetadata(urls) {
  const results = [];
  for (const url of urls) {
    try {
      const response = await axios.get(`https://api.linkpreview.net`, {
        params: {
          key: '2e4cdd1f899e7789056a8fcc4a07146f', // Replace with your Link Preview API key
          q: url.contentUrl
        }
      });
      results.push(response.data);
    } catch (error) {
      console.error(`Failed to fetch metadata for ${url}:`, error);
    }
  }
  return results;
}







