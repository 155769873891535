<template>
    <div class="not-found">
        <div >
      <img src="@/assets/images/notfoud.jpeg" alt="Profile Image" class="not-founf-img" />
    </div>
      <h1>Oops.</h1>
      <h2 >We can't find the SmartPage you're looking for.</h2>
      <p>It may have expired, or there could be a typo. Learn more about SmartPages from our homepage.</p>
      <a class="home-link" href="https://smartcardlabs.com/" target="_blank">Go to SmarCard Homepage</a>
    </div>
  </template>

  <script>
  export default {
    name: "NotFound",
  };
  </script>
  
  <style src="@/assets/styles/shared-styles.css">

  </style>
  