<template>
  <div class="video-card" v-if="videoContents && mysmartcard">
    <div class="heading">
      <h2><span class="yellow">About</span> <span class="black">Me</span></h2>
    </div>
    <div v-for="(video, index) in videoContents" :key="index">
      <YouTube :src="video.contentUrl" width="100%" height="100%" />
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import YouTube from "vue3-youtube";
export default {
  name: "VideoPage",
  props: {
    mysmartcard: {
      type: Object,
      Required: true,
    },
  },
  components: {
    YouTube,
  },
   setup(props) {
    //const videoId = ref(`https://www.youtube.com/embed/JjsKPodL57M`);
    //const copVideo = ref("https://youtu.be/fnQzX8dt1Uo?si=0JGCtvhkQMEIe7ng");
    // videoContents ;
    console.log("smartcard Contents is ; ", props.mysmartcard.SmartContent);

   // watchEffect(async () => {
      if (props.mysmartcard.SmartContent) {
        const videoContents = computed(() => {
          return props.mysmartcard.SmartContent.filter(
            (content) => content.contentType === "videoContent"
          );
        });
        if (videoContents.value) {
        console.log("videoContent value is ; ", videoContents.value);
      }
    //});


    return { videoContents };
  }else{
    const videoContents =ref(null);
    return { videoContents };
  }
   }

};
</script>

<style src="@/assets/styles/shared-styles.css"></style>
