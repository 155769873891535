import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { smartfireapp } from "./firebase/config";
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';

smartfireapp;

createApp(App)
  .use(
    router,
  )
  .use(
    PrimeVue, {
      theme: {
          preset: Aura
      }
  }
  )
  .mount("#app");
