<template>
 <!-- Main Content -->
 
        <div class="contact-card" v-if="mysmartcard">
          <div class="heading">
      <h2><span class="yellow">Contact</span> <span class="black">Info</span></h2>
    </div>
          <!-- Map Section -->
         <!-- <div class="map">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.8354345096695!2d144.95592331568284!3d-37.81720997975159!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad642af0f11fd81%3A0x5045675218ce6e0!2sApple!5e0!3m2!1sen!2sus!4v1618472565476!5m2!1sen!2sus"
              width="100%" height="100%" style="border:0;" allowfullscreen="" loading="lazy"></iframe>

          </div>-->
           
          <!-- Information Grid Section -->
          <div class="info-grid">
            <div class="info-item email" v-for="(email, index) in mysmartcard.emails" :key="index">
              <span>{{email.label}} email</span>
              <p>{{email.address}} </p>
            </div>
            <div class="info-item" v-for="(phone, index) in mysmartcard.phones" :key="index">
              <span>{{phone.label}} phone</span>
              <p>{{phone.number}}</p>
            </div>
            <div class="info-item" v-if="mysmartcard.addresses[0].address !==''">
              <span>Address</span>
              <p >{{mysmartcard.addresses[0].address}}, {{mysmartcard.addresses[0].country}} </p>
            </div>

            <!-- <div class="info-item green-text">
              <span>Freelance ......</span>
              <p>Available</p>
            </div> -->
          </div>
        </div>


        <!--<div class="contact-section">
          <h2><span class="green">Contact</span> <span class="black">Form</span></h2>
          <form id="contact-form">
            <div class="input-row">
              <input type="text" id="name" placeholder="Full Name" required>
              <input type="email" id="email" placeholder="Email Address" required>
            </div>
            <div class="input-row">
              <textarea id="message" placeholder="Your Message" rows="4" required></textarea>
            </div>
            <button type="submit" class="submit-btn">SEND MESSAGE <span>&#10132;</span></button>
          </form>
        </div>-->
  </template>
  
  <script >
  
  export default ({
    name: 'ContactInfo',
    props: {
    mysmartcard: {
      type: Object,
      Required: true,
    }
  },
  
  });
  </script>
  
  <style src="@/assets/styles/shared-styles.css">

  </style>